import React, { Fragment, memo } from 'react';

import PropTypes from 'prop-types';

// This component requires Contentful's Image API
// Inspiration: https://www.contentful.com/blog/2019/10/31/webp-source-sets-images-api/

const breakpoints = {
    medium: 768,
    large: 1024,
    xLarge: 1280,
    xxLarge: 1440,
    xxxLarge: 1920,
};

function getDefaultCustomSources(ogSrc) {
    const breakpointSources = Object.values(breakpoints).map(breakpoint => {
        return {
            breakpoint: breakpoint,
            src: ogSrc,
            imageWidth: breakpoint * 1.5,
        };
    });

    return [...breakpointSources, { src: ogSrc, imageWidth: 768 }];
}

const ContentfulImg = ({
    alt = '',
    className = null,
    customSources = [],
    decoding,
    draggable = false,
    fallbackImageWidth = 1280,
    loading = 'lazy',
    priority = false,
    src: ogSrc,
    ...rest
}) => {
    const isSVG = /.svg$/i.test(ogSrc);

    const hasNoFallbackCustomSource =
        customSources?.length > 0 &&
        customSources.every(source => source.breakpoint);

    hasNoFallbackCustomSource &&
        console.warn(
            'ContentfulImg.js: For optimization purposes, it is *highly recommended* that you include a fallback custom source with no breakpoint.'
        );

    // Round with to nearest integer to keep Contentful's Image API happy
    const fallbackWidth = Math.round(fallbackImageWidth);
    const isGif = /.gif$/i.test(ogSrc);
    const fallbackQuality = isGif ? 100 : 90;
    const quality = isGif ? 100 : 80;
    const sourcesCustom =
        customSources?.length > 0
            ? customSources
            : getDefaultCustomSources(ogSrc);
    const sources = isSVG ? [] : sourcesCustom;
    const source = isSVG
        ? ogSrc
        : `${ogSrc}?w=${fallbackWidth}&q=${fallbackQuality}&fm=webp`;

    return (
        <picture>
            {sources.map(
                (
                    { breakpoint, orientation, imageWidth, src: breakpointSrc },
                    i
                ) => {
                    // Max image values at 2560px and round width to nearest integer to keep Contentful's Image API happy
                    const w = Math.round(Math.min(imageWidth, 2560));

                    return (
                        <Fragment key={i}>
                            <source
                                media={
                                    orientation
                                        ? `(orientation: ${orientation})`
                                        : breakpoint &&
                                          `(min-width: ${breakpoint}px)`
                                }
                                srcSet={`${
                                    breakpointSrc ?? ogSrc
                                }?w=${w}&fm=webp&q=${quality}`}
                                type="image/webp"
                            />
                            <source
                                media={
                                    orientation
                                        ? `(orientation: ${orientation})`
                                        : breakpoint &&
                                          `(min-width: ${breakpoint}px)`
                                }
                                srcSet={`${
                                    breakpointSrc ?? ogSrc
                                }?w=${w}&q=${quality}`}
                            />
                        </Fragment>
                    );
                }
            )}
            <img
                alt={alt}
                src={source}
                className={className}
                draggable={draggable}
                loading={priority ? 'eager' : loading}
                decoding={priority ? 'sync' : decoding}
                // eslint-disable-next-line react/no-unknown-property
                fetchPriority={priority ? 'high' : 'low'}
                {...rest}
            />
        </picture>
    );
};

ContentfulImg.propTypes = {
    alt: PropTypes.string,
    className: PropTypes.string,
    customSources: PropTypes.array,
    decoding: PropTypes.oneOf(['async', 'sync', 'auto']),
    draggable: PropTypes.bool,
    fallbackImageWidth: PropTypes.number,
    loading: PropTypes.oneOf(['eager', 'lazy']),
    priority: PropTypes.bool,
    src: PropTypes.string.isRequired,
};

export default memo(ContentfulImg);
