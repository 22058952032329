'use client';

import React, { useState } from 'react';
import { useMount, useUnmount } from 'react-use';

import Script from 'next/script';

const Xola = () => {
    const [isMounted, setMounted] = useState(false);
    useMount(() => {
        if (window.xola) {
            window.xola.initialized = false;
            window.xola.init();
        } else {
            setMounted(true);
        }
    });

    useUnmount(() => {
        if (window.xola) {
            window.xola.initialized = false;
        }
    });

    return (
        <>
            {isMounted && (
                <Script id="xola-loader" src="https://xola.com/checkout.js" />
            )}
        </>
    );
};

Xola.propTypes = {};

export default Xola;
