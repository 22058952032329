'use client';

import React from 'react';

import PropTypes from 'prop-types';

import { useTheme } from 'hooks/useTheme';

import Text from './';

const TextThemed = ({
    config,
    children,
    /* eslint-disable-next-line no-unused-vars */
    themes = null, // unset
    /* eslint-disable-next-line no-unused-vars */
    baseTheme = null, // unset
    themeOverride,
    ...rest
}) => {
    const [theme] = useTheme();

    const newProps =
        config[themeOverride || theme || 'default'] || config['default'] || {};
    return (
        <Text {...rest} {...newProps}>
            {children}
        </Text>
    );
};

const SharedTextPropsTypes = PropTypes.shape({
    baseTheme: PropTypes.string,
    themes: PropTypes.object,
});

TextThemed.propTypes = {
    themeOverride: PropTypes.oneOf(['default', 'makers-reserve']),
    as: PropTypes.string,
    themes: PropTypes.any, // not used
    baseTheme: PropTypes.any, // not used
    children: PropTypes.node,
    className: PropTypes.string,
    config: PropTypes.shape({
        'makers-reserve': SharedTextPropsTypes,
        default: SharedTextPropsTypes,
    }).isRequired,
};

export default TextThemed;
