import React, { forwardRef } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import EntryLink from 'components/ui/Link/EntryLink';
import Icon from 'components/ui/SvgIcon';
import Xola from 'components/ui/Xola';

import styles from './ButtonCTA.module.scss';

const iconsToTheRight = [
    'download',
    'add',
    'share',
    'subtract',
    'arrowRight',
    'chevronRight',
];

const ButtonCTA = forwardRef(
    (
        { className, text, link, assetDownloadLink, style, iconType, ...rest },
        ref
    ) => {
        style ??= 'none';
        iconType ??= 'none';

        const entry = link || assetDownloadLink;

        const containsXolaReservation =
            link?.__typename === 'XolaReservationOverlay';

        const containsLink = !!entry && !containsXolaReservation;

        const rightAlignIcon = iconsToTheRight.find(i => i === iconType);

        const styleVars = style.split('-');
        const theme = styleVars[0];
        const classes = cx(
            styles.root,
            className,
            styles[`theme-${theme}`],
            styles[`colors-${style}`],
            {
                [styles.rightAlignIcon]: !!rightAlignIcon,
                [`xola-${link?.type || 'checkout'}`]: containsXolaReservation,
                'xola-custom': containsXolaReservation,
            }
        );

        const renderEntry = () => {
            const props = {
                'data-button-id': link?.xolaButtonId,
            };

            // return unstyled element
            if (style === 'none') {
                if (containsLink) {
                    return (
                        <EntryLink
                            className={cx(
                                styles.root,
                                className,
                                styles.unstyled
                            )}
                            entry={entry}
                            {...props}
                            {...rest}
                            ref={ref}
                        >
                            <span>{text ?? 'button'}</span>
                        </EntryLink>
                    );
                }
                return (
                    <button
                        className={cx(styles.root, className, styles.unstyled)}
                        {...props}
                        {...rest}
                        ref={ref}
                    >
                        <span>{text ?? 'button'}</span>
                    </button>
                );
            }

            if (!containsLink) {
                return (
                    <button className={classes} {...props} {...rest} ref={ref}>
                        {iconType !== 'none' && (
                            <Icon className={styles.icon} type={iconType} />
                        )}
                        <span>{text ?? 'button'}</span>
                    </button>
                );
            }

            return (
                <EntryLink
                    className={classes}
                    entry={entry}
                    {...props}
                    {...rest}
                    ref={ref}
                >
                    {iconType !== 'none' && (
                        <Icon className={styles.icon} type={iconType} />
                    )}
                    <span>{text ?? 'button'}</span>
                </EntryLink>
            );
        };
        return (
            <>
                {containsXolaReservation && <Xola />}
                {renderEntry()}
            </>
        );
    }
);

ButtonCTA.propTypes = {
    className: PropTypes.string,
    text: PropTypes.string,
    link: PropTypes.object,
    assetDownloadLink: PropTypes.object,
    style: PropTypes.oneOf([
        'none',
        'filled-blue-ocean',
        'filled-blue-cream',
        'filled-cream-white',
        'filled-cream-ocean',
        'filled-custom',
        'outlined-blue-blue',
        'outlined-cream-cream',
        'inline-blue-ocean',
        'inline-blue-cream',
        'inline-cream-white',
        'makersFilled-cream-black',
        'makersFilled-gold-cream',
        'makersOutlined-cream-cream',
        'makersOutlined-gold-gold',
    ]),
    iconType: PropTypes.oneOf([
        'none',
        'download',
        'add',
        'explore',
        'heart',
        'location',
        'search',
        'share',
        'subtract',
        'arrowRight',
        'arrowLeft',
        'chevronRight',
    ]),
};

export default ButtonCTA;
