'use client';

import React, { createContext } from 'react';

import PropTypes from 'prop-types';

export const BlockContext = createContext();

const BlockProvider = ({ children, id }) => {
    return <BlockContext.Provider value={id}>{children}</BlockContext.Provider>;
};

BlockProvider.propTypes = {
    children: PropTypes.object.isRequired,
    id: PropTypes.string,
};

export default BlockProvider;
