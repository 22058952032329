'use client';

import React, { forwardRef, useContext } from 'react';

import cx from 'classnames';
import kebabCase from 'lodash/kebabCase';
import NextLink from 'next/link';
import { usePathname } from 'next/navigation';
import PropTypes from 'prop-types';

import { BlockContext } from 'components/blocks/BlockProvider';

import { isExternalURL, isUtilityURL } from 'utils';
import * as klaviyo from 'utils/klaviyo';

import styles from './Link.module.scss';

const Link = forwardRef(
    (
        {
            as = '',
            children = null,
            className = '',
            draggable = false,
            href = '',
            scroll = true,
            target = '',
            onClick = null,
            ...props
        },
        ref
    ) => {
        const isExternalHref = isExternalURL(href);
        const blockContext = useContext(BlockContext);
        const pathname = usePathname();
        const targetProps = {
            target: target ? target : isExternalHref ? '_blank' : null,
            rel:
                isExternalHref && target !== '_self'
                    ? 'noreferrer noopener'
                    : null,
        };

        const trackingPathId = kebabCase(pathname.split('?')[0]);
        const hrefId = kebabCase(as || href);

        const handleOnClick = e => {
            klaviyo.trackExternalLink(href);

            onClick && onClick(e);
        };

        return isExternalHref || isUtilityURL(href) ? (
            <a
                gtm-id={`${trackingPathId}-${
                    blockContext && blockContext.substring(0, 6)
                }-${hrefId}`}
                {...props}
                {...targetProps}
                className={cx(styles.link, className)}
                href={as ? as : href}
                draggable={draggable}
                onDragStart={e => (draggable ? null : e.preventDefault())}
                onClick={handleOnClick}
                ref={ref}
            >
                {children}
            </a>
        ) : (
            <NextLink
                gtm-id={`${trackingPathId}-${
                    blockContext && blockContext.substring(0, 6)
                }-${hrefId}`}
                href={as ? as : href}
                as={as}
                scroll={scroll}
                {...props}
                {...targetProps}
                className={cx(styles.link, className)}
                onDragStart={e => (draggable ? null : e.preventDefault())}
                ref={ref}
            >
                {children}
            </NextLink>
        );
    }
);

Link.propTypes = {
    as: PropTypes.string,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    draggable: PropTypes.bool,
    href: PropTypes.string,
    scroll: PropTypes.bool,
    target: PropTypes.string,
    onClick: PropTypes.func,
};

// EntryLink = EntryLink;

export default Link;
