'use client';

import React, { useContext } from 'react';

import Link from '.';
import kebabCase from 'lodash/kebabCase';
import { usePathname } from 'next/navigation';
import PropTypes from 'prop-types';

import { BlockContext } from 'components/blocks/BlockProvider';

import { getEntryLink } from 'utils/getEntryLink';

const EntryLink = React.forwardRef((props, ref) => {
    const blockContext = useContext(BlockContext);
    const pathname = usePathname();
    const trackingPath = kebabCase(pathname.split('?')[0]);

    if (!props.entry) {
        return <span className={props.className}>{props.children}</span>;
    }

    const trackingId = () => {
        const entryId = props.entry.id || '';
        if (blockContext) {
            return `${trackingPath}-${blockContext.substring(
                0,
                6
            )}-${entryId.substring(0, 6)}`;
        }

        return `${entryId.substring(0, 6)}`;
    };

    return (
        <Link
            {...props}
            {...getEntryLink(props.entry)}
            gtm-id={trackingId()}
            entry={undefined}
            ref={ref}
        />
    );
});

EntryLink.propTypes = {
    entry: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    children: PropTypes.node,
    className: PropTypes.string,
    onClick: PropTypes.func,
};

export default EntryLink;
