export const getEntryLink = entry => {
    const { __typename = 'unknown', slug } = entry;

    // handle asset downloads
    if (entry?.url) {
        return { href: entry?.url, download: true, target: undefined };
    }

    switch (__typename) {
        case 'Page':
            return { href: `/${entry.slug}` };
        case 'ExternalLink':
            return { href: entry.url, target: entry.target };
        case 'ManualLink':
            if (/^quiz/i.test(entry.as)) {
                return {
                    href: `/${entry.as}`,
                    scroll: false,
                };
            }
            return {
                href: `/${entry.as}`,
            };
        case 'XolaReservationOverlay':
            return {};
        case 'Recipe':
            return { href: `/recipes/${slug}` };
        case 'News':
            return { href: `/news/${slug}` };
        case 'FaqCategory':
            return {
                href: `/faq/${entry.slug}`,
            };
        case 'Faq':
            return {
                href: `/faq/${entry.category.slug}/${entry.slug}`,
            };
        case 'ProductCategory':
            return {
                href: `/products/${slug}`,
            };
        case 'Product':
            return {
                href: `/products/${entry.category.slug}/${slug}`,
            };
        case 'Quiz':
            return {
                href: `/quiz/${entry.slug}`,
                scroll: false,
            };
        case 'FoodServiceProductCategory':
            return {
                href: `/foodservice/products/${slug}`,
            };
        case 'FoodServiceProduct':
            return {
                href: `/foodservice/products/${entry.category.slug}/${slug}`,
            };
        case 'AllForFarmersStory':
            return {
                href: `/all-for-farmers/${slug}`,
            };

        default:
            return {
                href: slug === '/' ? '/' : `/${slug}`,
            };
    }
};
