import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import icons from './icons';

import styles from './Icon.module.scss';

const getId = type => `__icon_${type}__`;

const Icon = ({ className, type, ...otherProps }) => {
    const iconDef = icons[type];

    if (!iconDef) {
        return <p>Icon {type} is missing</p>;
    }

    const viewBox = `0 0 ${iconDef.viewBox.width} ${iconDef.viewBox.height}`;

    return (
        <span
            aria-hidden="true"
            className={cx(styles.root, className)}
            style={{
                '--width': `${iconDef.viewBox.width}px`,
                '--height': `${iconDef.viewBox.height}px`,
            }}
            {...otherProps}
        >
            <svg viewBox={viewBox} xmlns="http://www.w3.org/2000/svg">
                <use xlinkHref={`#${getId(type)}`}></use>
            </svg>
        </span>
    );
};

Icon.propTypes = {
    className: PropTypes.string,
    type: PropTypes.string.isRequired,
};

export default Icon;
